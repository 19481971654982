<template>
	<v-container>
		<p>
			Uses SponsorBlock data from <a href="https://sponsor.ajay.app">https://sponsor.ajay.app</a>
		</p>
	</v-container>
</template>

<script>
export default {
	name: 'Attribution',
};
</script>
