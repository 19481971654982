<template>
  <v-container>
    <h1>Privacy Policy</h1>
    <p>
      This site uses cookies. It also uses Google Analytics, but only the smallest subset of information is tracked. The only demographic information that is collected is country, and whether you are on desktop or mobile. The collected data is never associated with your OTT account or session. If you do not want this information collected, use an ad blocker.
    </p>

    <p>
      Your IP is not logged in OpenTogetherTube's logs. However, it is recorded for a short period of time for rate limiting.
      Chats are not recorded. What you search for in the "add video" search box is never associated with your OTT account or session.
    </p>

    <p>
      General site usage, like creating a room, adding videos, etc., are logged to monitor and debug performace. Logs are not kept for more than a week.
      Logged events are not associated with your account or session.
    </p>

    <p>
      If you have registered an account, your email is only used for account recovery, or to contact you in case it is necessary to resolve a bug, or follow up on site feedback. An email is not required if you log in with Discord. Your email, owned rooms, or other account information is your private information, and as such will never be distributed or sold to third parties.
    </p>

    <p>
      OpenTogetherTube is GDPR compliant because of how little information is collected. If, for some reason, you really want to have the one database row that represents your account, reach out to me <a href="https://twitter.com/rollthedyc3">on twitter.</a>
    </p>

    <p>
      This site uses the Youtube Data API, and it's usage must comply with the <a href="https://developers.google.com/youtube/terms/api-services-terms-of-service">YouTube API Terms of Service</a>.
      No personally identifiable information is sent to Youtube. Watching Youtube videos requires you to agree to the <a href="https://www.youtube.com/t/terms">Youtube Terms of Service</a>,
      and <a href="https://policies.google.com/privacy">Google's privacy policy</a>.
    </p>
  </v-container>
</template>

<script>
export default {
  name: "privacypolicy",
};
</script>

<style lang="scss" scoped>

</style>
